.App {
  text-align: center;
}
.App > div {
    padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
    font-weight: 700 !important;
}

/* Custom Styles */
.sf-footer {
    margin: 10px;
}

/* Override SF Styles */
.slds-page-header__title {
    color: rgba(0,0,0,0.7);
}
.slds-card {
    box-shadow: 0px 2px 6px rgba(0,0,0,0.16) !important;
    border: 1px solid #fff !important;
    padding: 1px !important;
    background-color: #f9f9f9 !important;
}
.slds-text-heading_small {
    /* font-size: 1.5rem !important; */
    font-weight: 700 !important;
    color: #0176D3 !important;
}
.slds-form-element {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
}
.slds-form-element__control {
    display: flex !important;
    flex: 1;
}
.slds-form-element__control .slds-input {
    width: 100% !important;
    display: flex !important;
    flex: -1 !important;
}
.slds-form-element__control:has(> .column) {
    flex-direction: column !important;
    align-items: flex-start !important;
}
.slds-input, .slds-textarea {
    color: #0176D3 !important;
    margin: 3px;
    border: 0 !important;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.16) !important;
}
.slds-input:disabled {
    border-top: 1px solid #fff !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    border-bottom: 1px solid #ddd !important;
}
.slds-textarea {
    height: 200px;
}
.slds-form-element__label {
    width: 120px;
    font-weight: 700;
    text-align: right;
}
.slds-button {
    border: 0 !important;
    background-color: #0070d2 !important;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.16) !important;
    color: #fff !important;
    font-weight: 700 !important;
}
.slds-button_icon {
    background-color: rgba(0,0,0,0) !important;
    color: #0176D3 !important;
}
/* .slds-button__icon {
    background-color: rgba(0,0,0,0) !important;
    color: #0176D3 !important;
} */
